// 3rd
import { Box } from '@chakra-ui/layout';
import type { SystemStyleObject } from '@chakra-ui/system';
import { useMultiStyleConfig } from '@chakra-ui/system';
import type {
  ContainerProps as ReactSelectContainerProps,
  GroupBase as ReactSelectGroupBase,
  IndicatorsContainerProps as ReactSelectIndicatorsContainerProps,
  ValueContainerProps as ReactSelectValueContainerProps,
} from 'react-select';

// App - Other
import { useSize } from '../hooks/use-size';

export const SelectContainer = <
  Option,
  IsMulti extends boolean,
  Group extends ReactSelectGroupBase<Option>,
>(
  props: ReactSelectContainerProps<Option, IsMulti, Group>
) => {
  const {
    children,
    className,
    cx,
    innerProps,
    isDisabled,
    isRtl,
    hasValue,
    selectProps: { chakraStyles },
  } = props;

  const initialSx: SystemStyleObject = {
    position: 'relative',
    w: '100%',
    direction: isRtl ? 'rtl' : undefined,
    ...(isDisabled ? { cursor: 'not-allowed' } : {}),
  };

  const sx = chakraStyles?.container ? chakraStyles.container(initialSx, props) : initialSx;

  return (
    <Box
      {...innerProps}
      className={cx(
        {
          '--is-disabled': isDisabled,
          '--is-rtl': isRtl,
          '--has-value': hasValue,
        },
        className,
        'select-container'
      )}
      sx={sx}
    >
      {children}
    </Box>
  );
};

export const ValueContainer = <
  Option,
  IsMulti extends boolean,
  Group extends ReactSelectGroupBase<Option>,
>(
  props: ReactSelectValueContainerProps<Option, IsMulti, Group>
) => {
  const {
    children,
    className,
    cx,
    isMulti,
    hasValue,
    innerProps,
    selectProps: {
      chakraStyles,
      size: sizeProp,
      variant,
      focusBorderColor,
      errorBorderColor,
      controlShouldRenderValue,
    },
  } = props;

  const size = useSize(sizeProp);

  // Getting the css from input instead of select
  // to fit better with each of the variants
  const inputStyles = useMultiStyleConfig('Input', {
    size,
    variant,
    focusBorderColor,
    errorBorderColor,
  });

  const initialSx: SystemStyleObject = {
    display: isMulti && hasValue && controlShouldRenderValue ? 'flex' : 'grid',
    alignItems: 'center',
    flex: 1,
    paddingY: '2px',
    paddingX: inputStyles.field.px,
    flexWrap: 'wrap',
    WebkitOverflowScrolling: 'touch',
    position: 'relative',
    overflow: 'hidden',
  };

  const sx = chakraStyles?.valueContainer
    ? chakraStyles.valueContainer(initialSx, props)
    : initialSx;

  return (
    <Box
      {...innerProps}
      className={cx(
        {
          'value-container': true,
          'value-container--is-multi': isMulti,
          'value-container--has-value': hasValue,
        },
        className,
        'select-value-container'
      )}
      sx={sx}
    >
      {children}
    </Box>
  );
};

export const IndicatorsContainer = <
  Option,
  IsMulti extends boolean,
  Group extends ReactSelectGroupBase<Option>,
>(
  props: ReactSelectIndicatorsContainerProps<Option, IsMulti, Group>
) => {
  const {
    children,
    className,
    cx,
    innerProps,
    selectProps: { chakraStyles },
  } = props;

  const initialSx: SystemStyleObject = {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flexShrink: 0,
  };

  const sx = chakraStyles?.indicatorsContainer
    ? chakraStyles.indicatorsContainer(initialSx, props)
    : initialSx;

  return (
    <Box
      {...innerProps}
      className={cx(
        {
          indicators: true,
        },
        className,
        'select-indicators-container'
      )}
      sx={sx}
    >
      {children}
    </Box>
  );
};
