export const APP_URL = process.env.NEXT_PUBLIC_APP_URL as string;
export const ENVIRONMENT = APP_URL.includes('localhost')
  ? 'local'
  : APP_URL.includes('app-dev')
    ? 'dev'
    : APP_URL.includes('app-stag')
      ? 'stag'
      : 'prod';
export const API_URL = process.env.NEXT_PUBLIC_API_URL as string;
export const API_MOCKING = process.env.NEXT_PUBLIC_API_MOCKING === 'true';
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_TEST = process.env.NODE_ENV === 'test';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_BROWSER = typeof window !== 'undefined';
export const IS_SERVER = typeof window === 'undefined';
export const FRONTEGG_APP_URL = process.env.FRONTEGG_APP_URL as string;
export const FRONTEGG_BASE_URL = process.env.FRONTEGG_BASE_URL as string;
export const FRONTEGG_CLIENT_ID = process.env.FRONTEGG_CLIENT_ID as string;
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string;
export const SPLITIO_API_KEY = process.env.NEXT_PUBLIC_SPLITIO_API_KEY as string;
export const SPLITIO_IDENTIFIER_HEADER = 'x-split-identifier';
export const SPLITIO_USER_COOKIE = 'splitKey';
export const DATADOG_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string;
export const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string;
export const DATADOG_SESSION_SAMPLE_RATE = parseInt(
  process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE as string
);
export const DATADOG_SESSION_REPLAY_SAMPLE_RATE = parseInt(
  process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_SAMPLE_RATE as string
);
export const DATADOG_RELEASE_VERSION = APP_URL.includes('localhost')
  ? 'local'
  : APP_URL.includes('app-dev')
    ? 'development'
    : APP_URL.includes('app-stag')
      ? 'staging'
      : 'production';
