// React & Next
import type { ReactElement, MouseEvent } from 'react';

// 3rd
import {
  forwardRef,
  Tag as ChakraTag,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  TagLabel,
} from '@chakra-ui/react';
import type { TagProps as ChakraTagProps } from '@chakra-ui/react';

export type TagProps = Omit<
  ChakraTagProps,
  | 'isInvalid'
  | '_active'
  | '_hover'
  | '_disabled'
  | '_loading'
  | '_after'
  | '_before'
  | '_hidden'
  | '_placeholder'
  | '_placeholderShown'
  | '_selected'
  | '_selection'
  | '_checked'
  | '_visited'
  | '_valid'
  | '_invalid'
  | '_rtl'
  | '_ltr'
  | '_dark'
  | '_mediaReduceMotion'
  | '_mediaDark'
  | '_light'
  | '_vertical'
  | '_highlighted'
  | '_horizontal'
  | '_indeterminate'
  | '_empty'
  | '_even'
  | '_odd'
  | '_expanded'
  | '_last'
  | '_notLast'
  | '_notFirst'
  | '_first'
  | '_firstLetter'
  | '_focus'
  | '_focusVisible'
  | '_focusWithin'
  | '_fullScreen'
  | '_grabbed'
  | '_groupActive'
  | '_groupChecked'
  | '_groupDisabled'
  | '_groupFocus'
  | '_groupFocusVisible'
  | '_groupFocusWithin'
  | '_groupHover'
  | '_groupInvalid'
  | '_activeLink'
  | '_activeStep'
  | '_autofill'
  | '_peerActive'
  | '_peerChecked'
  | '_peerDisabled'
  | '_peerFocus'
  | '_peerFocusVisible'
  | '_peerFocusWithin'
  | '_peerHover'
  | '_peerInvalid'
  | '_peerPlaceholderShown'
  | '_pressed'
  | '_readOnly'
> & {
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  leftElement?: ReactElement;
  rightElement?: ReactElement;
  onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
  onClose?: () => void;
};

export const Tag = forwardRef(
  (
    {
      variant,
      size,
      onClick,
      onClose,
      leftIcon,
      rightIcon,
      leftElement,
      rightElement,
      children,
      ...props
    }: TagProps,
    ref
  ) => {
    return (
      <ChakraTag
        cursor={onClick ? 'pointer' : 'default'}
        {...props}
        variant={variant}
        size={size || 'sm'}
        onClick={onClick}
        ref={ref}
      >
        {leftIcon ? <TagLeftIcon>{leftIcon}</TagLeftIcon> : null}

        {leftElement ? leftElement : null}

        <TagLabel className="tag-label">{children}</TagLabel>

        {rightElement ? rightElement : null}

        {onClose ? <TagCloseButton onClick={onClose} /> : null}

        {rightIcon ? <TagRightIcon>{rightIcon}</TagRightIcon> : null}
      </ChakraTag>
    );
  }
);
