// React & Next
import type { ReactNode } from 'react';

// 3rd
import { Flex, forwardRef } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

type IconBoxProps = Partial<FlexProps> & {
  children: ReactNode;
};

export const IconBox = forwardRef(({ children, ...flexProps }: IconBoxProps, ref) => {
  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius={'12px'}
      {...flexProps}
      ref={ref}
    >
      {children}
    </Flex>
  );
});
