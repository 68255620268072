/* eslint-disable @typescript-eslint/no-unused-vars */
// 3rd
import type {
  CommonPropsAndClassName as ReactSelectCommonPropsAndClassName,
  GroupBase as ReactSelectGroupBase,
} from 'react-select';

/**
 * Clean Common Props
 *
 * Borrowed from the original `react-select` package
 *
 * @see {@link https://github.com/JedWatson/react-select/blob/2f94e8d/packages/react-select/src/utils.ts#L79-L110}
 */
export const cleanCommonProps = <
  Option,
  IsMulti extends boolean,
  Group extends ReactSelectGroupBase<Option>,
  AdditionalProps,
>(
  props: Partial<ReactSelectCommonPropsAndClassName<Option, IsMulti, Group>> & AdditionalProps
): Omit<AdditionalProps, keyof ReactSelectCommonPropsAndClassName<Option, IsMulti, Group>> => {
  // className
  const {
    className, // not listed in commonProps documentation, needs to be removed to allow Emotion to generate classNames
    clearValue,
    cx,
    getStyles,
    getClassNames,
    getValue,
    hasValue,
    isMulti,
    isRtl,
    options, // not listed in commonProps documentation
    selectOption,
    selectProps,
    setValue,
    theme, // not listed in commonProps documentation
    ...innerProps
  } = props;

  return { ...innerProps };
};
