// React & Next
import { useMemo, useState } from 'react';
import type { ReactElement } from 'react';

// 3rd
import { useDropzone } from 'react-dropzone';
import { Flex } from '@chakra-ui/react';
import type { FlexProps, SystemProps } from '@chakra-ui/react';

// App - Other
import Locale from '@/locale/en.json';
import { CloseIcon, DocumentIcon } from '@/components/atoms/icon';
import { Heading, Text } from '@/components/atoms/typography';

const locale = Locale.components.form['upload-field'];

type UploadFieldProps = {
  isDisabled?: boolean;
  onUpload?: (acceptedFile?: File) => void;
};

export const UploadField = ({ isDisabled, onUpload }: UploadFieldProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<(File & { preview: ReactElement })[]>([]);
  const {
    getRootProps,
    getInputProps,
    rootRef,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles: File[]) => {
      setUploadedFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: getSvgForFileType(file.type),
          })
        )
      );

      onUpload?.(acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : undefined);
      rootRef.current?.blur();
    },
  });

  const style: FlexProps = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Flex direction="column" gap="24px">
      {uploadedFiles && uploadedFiles.length ? (
        uploadedFiles.map((file) => (
          <Flex key={file.name} alignItems="center" justifyItems="flex-start" gap="8px">
            <CloseIcon
              size="3xs"
              ms="8px"
              cursor="pointer"
              color="#FF5B5B"
              onClick={() => {
                setUploadedFiles([]);
                onUpload?.(undefined);
              }}
              aria-label={locale['Remove file']}
            />

            {file.preview}
            {/*<Icon as={} size="lg" />*/}

            <Text variant="detail" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {file.name}
            </Text>
          </Flex>
        ))
      ) : (
        <Flex {...style} {...getRootProps()}>
          <input {...getInputProps()} disabled={isDisabled} />

          {isDragActive ? (
            <Heading color="#bdbdbd">{locale['Drop the files here...']}</Heading>
          ) : (
            <Heading color="#bdbdbd">
              {locale['Drag & drop some files here, or click to select files']}
            </Heading>
          )}
        </Flex>
      )}
    </Flex>
  );
};

const baseStyle = {
  flex: 1,
  direction: 'column' as SystemProps['flexDirection'],
  alignItems: 'center',
  padding: '80px',
  borderWidth: 2,
  borderRadius: 4,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2683FE',
};

const acceptStyle = {
  borderColor: '#2683FE',
};

const rejectStyle = {
  borderColor: '#FF5B5B',
};

const getSvgForFileType = (type: string) => {
  if (type === 'application/pdf') return <DocumentIcon size="lg" aria-label="PDF" />;
  if (type === 'application/x-yaml') return <DocumentIcon size="lg" aria-label="Yaml" />;
  if (type === 'video/mp4') return <DocumentIcon size="lg" aria-label="MP4" />;

  if (type === 'image/svg+xml') return <DocumentIcon size="lg" aria-label="SVG" />;
  if (type === 'image/png') return <DocumentIcon size="lg" aria-label="PNG" />;
  if (type === 'image/jpeg') return <DocumentIcon size="lg" aria-label="JPEG" />;
  if (type === 'image/vnd.microsoft.icon') return <DocumentIcon size="lg" aria-label="Image" />;
  if (type === 'application/json') return <DocumentIcon size="lg" aria-label="JSON" />;
  if (type === 'text/html') return <DocumentIcon size="lg" aria-label="HTML" />;
  if (type === 'image/gif') return <DocumentIcon size="lg" aria-label="GIF" />;
  if (type === 'text/plain') return <DocumentIcon size="lg" aria-label="Plain" />;
  if (type === 'text/csv') return <DocumentIcon size="lg" aria-label="CSV" />;
  if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    return <DocumentIcon size="lg" aria-label="Spreadsheet" />;

  return <DocumentIcon size="lg" aria-label="Other" />;
};
