// 3rd
import { InputGroup } from '@chakra-ui/react';
import type { InputProps } from './input';

// App - Other
import { Input } from './input';

export type KeyValueInputProps = InputProps & {
  keyValue: { key: string; value: string };
  keyValuePlaceholder?: { key: string; value: string };
  onChangeKey: (key: string) => void;
  onChangeValue: (value: string) => void;
  isDisabled?: boolean;
};

export const KeyValueInput = ({
  keyValue,
  keyValuePlaceholder,
  onChangeKey,
  onChangeValue,
  isDisabled,
  ...rest
}: KeyValueInputProps) => {
  return (
    <InputGroup gap="sm" {...rest}>
      <Input
        value={keyValue.key}
        placeholder={keyValuePlaceholder?.key}
        onChange={(e) => onChangeKey(e.target.value)}
        isDisabled={isDisabled}
      />

      <Input
        value={keyValue.value}
        placeholder={keyValuePlaceholder?.value}
        onChange={(e) => onChangeValue(e.target.value)}
        isDisabled={isDisabled}
      />
    </InputGroup>
  );
};
