// React & Next
import { isValidElement } from 'react';
import type { ReactElement } from 'react';

// 3rd
import {
  forwardRef,
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import type {
  InputProps as ChakraInputProps,
  InputGroupProps as ChakraInputGroupProps,
} from '@chakra-ui/react';

export type InputProps = Omit<
  ChakraInputProps,
  | '_active'
  | '_hover'
  | '_disabled'
  | '_loading'
  | '_after'
  | '_before'
  | '_hidden'
  | '_placeholder'
  | '_placeholderShown'
  | '_selected'
  | '_selection'
  | '_checked'
  | '_visited'
  | '_valid'
  | '_invalid'
  | '_rtl'
  | '_ltr'
  | '_dark'
  | '_mediaReduceMotion'
  | '_mediaDark'
  | '_light'
  | '_vertical'
  | '_highlighted'
  | '_horizontal'
  | '_indeterminate'
  | '_empty'
  | '_even'
  | '_odd'
  | '_expanded'
  | '_last'
  | '_notLast'
  | '_notFirst'
  | '_first'
  | '_firstLetter'
  | '_focus'
  | '_focusVisible'
  | '_focusWithin'
  | '_fullScreen'
  | '_grabbed'
  | '_groupActive'
  | '_groupChecked'
  | '_groupDisabled'
  | '_groupFocus'
  | '_groupFocusVisible'
  | '_groupFocusWithin'
  | '_groupHover'
  | '_groupInvalid'
  | '_activeLink'
  | '_activeStep'
  | '_autofill'
  | '_peerActive'
  | '_peerChecked'
  | '_peerDisabled'
  | '_peerFocus'
  | '_peerFocusVisible'
  | '_peerFocusWithin'
  | '_peerHover'
  | '_peerInvalid'
  | '_peerPlaceholderShown'
  | '_pressed'
  | '_readOnly'
> & {
  leftAddon?: string | ReactElement | null;
  rightAddon?: string | ReactElement | null;
  groupProps?: ChakraInputGroupProps;
};

export const Input = forwardRef(
  ({ variant, size, leftAddon, rightAddon, zIndex, groupProps, ...props }: InputProps, ref) => {
    const isOld = !!variant && typeof variant === 'string' && variant.includes('old');
    const sizeValue = `${isOld && !((size as string) || '').includes('old-') ? 'old-' : ''}${size ? size : 'md'}`;

    return (
      <InputGroup variant={variant || 'outline'} size={sizeValue} zIndex={zIndex} {...groupProps}>
        {leftAddon && typeof leftAddon === 'string' ? (
          <InputLeftAddon {...(zIndex ? { zIndex: parseInt(`${zIndex}`) + 1 } : {})}>
            {leftAddon}
          </InputLeftAddon>
        ) : leftAddon && isValidElement(leftAddon) ? (
          <InputLeftElement {...(zIndex ? { zIndex: parseInt(`${zIndex}`) + 1 } : {})}>
            {leftAddon}
          </InputLeftElement>
        ) : null}

        <ChakraInput {...props} variant={variant || 'outline'} size={sizeValue} ref={ref} />

        {rightAddon && typeof rightAddon === 'string' ? (
          <InputRightAddon {...(zIndex ? { zIndex: parseInt(`${zIndex}`) + 1 } : {})}>
            {rightAddon}
          </InputRightAddon>
        ) : rightAddon && isValidElement(rightAddon) ? (
          <InputRightElement {...(zIndex ? { zIndex: parseInt(`${zIndex}`) + 1 } : {})}>
            {rightAddon}
          </InputRightElement>
        ) : null}
      </InputGroup>
    );
  }
);
