// 3rd
import { forwardRef, Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import type { CheckboxProps as ChakraCheckboxProps } from '@chakra-ui/react';

export type CheckboxProps = Omit<
  ChakraCheckboxProps,
  | '_active'
  | '_hover'
  | '_disabled'
  | '_loading'
  | '_after'
  | '_before'
  | '_hidden'
  | '_placeholder'
  | '_placeholderShown'
  | '_selected'
  | '_selection'
  | '_checked'
  | '_visited'
  | '_valid'
  | '_invalid'
  | '_rtl'
  | '_ltr'
  | '_dark'
  | '_mediaReduceMotion'
  | '_mediaDark'
  | '_light'
  | '_vertical'
  | '_highlighted'
  | '_horizontal'
  | '_indeterminate'
  | '_empty'
  | '_even'
  | '_odd'
  | '_expanded'
  | '_last'
  | '_notLast'
  | '_notFirst'
  | '_first'
  | '_firstLetter'
  | '_focus'
  | '_focusVisible'
  | '_focusWithin'
  | '_fullScreen'
  | '_grabbed'
  | '_groupActive'
  | '_groupChecked'
  | '_groupDisabled'
  | '_groupFocus'
  | '_groupFocusVisible'
  | '_groupFocusWithin'
  | '_groupHover'
  | '_groupInvalid'
  | '_activeLink'
  | '_activeStep'
  | '_autofill'
  | '_peerActive'
  | '_peerChecked'
  | '_peerDisabled'
  | '_peerFocus'
  | '_peerFocusVisible'
  | '_peerFocusWithin'
  | '_peerHover'
  | '_peerInvalid'
  | '_peerPlaceholderShown'
  | '_pressed'
  | '_readOnly'
> & {
  size?: 'sm' | 'md' | 'lg';
};

export const Checkbox = forwardRef(({ size, ...props }: CheckboxProps, ref) => {
  return <ChakraCheckbox {...props} size={size || 'md'} ref={ref} />;
});
