// 3rd
import { DateTime } from 'luxon';

export const formatISODate = (date: string) => {
  try {
    const dt = DateTime.fromISO(date, { zone: 'utc' });
    return dt.toLocaleString(DateTime.DATE_MED);
  } catch (e) {
    return date;
  }
};

export const formatISODateTime = (date: string) => {
  try {
    const dt = DateTime.fromISO(date, { zone: 'utc' });
    return `${dt.toLocaleString(DateTime.DATE_MED)} ${dt.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
  } catch (e) {
    return date;
  }
};

export const formatDateFromCustomFormat = (dateStr: string, frmt = 'yyyy-MM-dd HH:mm:ss') => {
  try {
    const dt = DateTime.fromFormat(dateStr, frmt);
    return dt.toLocaleString(DateTime.DATE_MED);
  } catch (e) {
    return dateStr;
  }
};

export const formatISODateFromCustomFormat = (
  dateStr: string,
  frmt = 'yyyy-MM-dd HH:mm:ss'
): string => {
  try {
    const dt = DateTime.fromFormat(dateStr, frmt);
    return dt.toJSDate().toISOString();
  } catch (e) {
    return dateStr;
  }
};

export const formatDateTimeFromCustomFormat = (dateStr: string, frmt = 'yyyy-MM-dd HH:mm:ss') => {
  try {
    const dt = DateTime.fromFormat(dateStr, frmt);
    return dt.toFormat('dd/LL/yy HH:mm');
  } catch (e) {
    return dateStr;
  }
};

export const normalizeDatesToISOString = (datetime: string): DateTime => {
  let isoDate = DateTime.fromISO(datetime, { zone: 'utc' });

  if (isoDate.toString() === 'Invalid DateTime') {
    isoDate = DateTime.fromFormat(datetime, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' });
  }

  return isoDate;
};
