// React & Next
import type { MutableRefObject, ReactElement, RefAttributes } from 'react';

// 3rd
import ReactSelect from 'react-select';
import type {
  GroupBase as ReactSelectGroupBase,
  Props as ReactSelectProps,
  SelectInstance as ReactSelectSelectInstance,
} from 'react-select';
import { forwardRef } from '@chakra-ui/react';

// App - Other
import { useChakraSelectProps } from './hooks/use-chakra-select-props';

type Select = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends ReactSelectGroupBase<Option> = ReactSelectGroupBase<Option>,
>(
  props: ReactSelectProps<Option, IsMulti, Group> &
    RefAttributes<ReactSelectSelectInstance<Option, IsMulti, Group>>
) => ReactElement;

export const Select = forwardRef(
  <Option, IsMulti extends boolean, Group extends ReactSelectGroupBase<Option>>(
    props: ReactSelectProps<Option, IsMulti, Group>,
    ref:
      | ((instance: ReactSelectSelectInstance<Option, IsMulti, Group> | null) => void)
      | MutableRefObject<ReactSelectSelectInstance<Option, IsMulti, Group> | null>
      | null
  ) => {
    const chakraSelectProps = useChakraSelectProps(props);

    return <ReactSelect ref={ref} {...chakraSelectProps} />;
  }
) as Select;
